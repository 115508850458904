.videoWrapper {
    margin: 120px auto;
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 8px;
}

.qrVideo {
    width: 200px;
    height: 200px;
    object-fit: cover;
    object-position: center;
}
.admissible {
    display: flex;
    align-items: center;
    margin-top: 12px;
}

.admissible img {
    margin-right: auto;
    margin-left: auto;
}

.validity {
    margin-top: 12px;
}

.validityBlock {
    display: flex;
    align-items: center;
}

.validityBlock p {
    font-size: 14px;
    color: white;
    margin-right: auto;
    margin-left: auto;
}

.validityComment {
    margin-top: 12px;
}

.validityCommentBlock {
    display: flex;
    align-items: center;
}

.validityCommentBlock p {
    font-size: 10px;
    color: var(--main-gray-color);
    margin-right: auto;
    margin-left: auto;
}

.validityCommentBlock img {
    padding-right: 4px;
}

.greenBlock {
    cursor: pointer;
    margin-top: 12px;
    background-color: #3A427B;
    border-radius: 5px;
}

.greenSwapBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 12px;
    border-radius: 5px;
    min-height: 50px;
    background:
        linear-gradient(90deg, rgb(2, 0, 36) 0%, rgb(11, 187, 177) 0%, rgb(6, 120, 189) 100%);
}

.greenText {
    display: flex;
    align-items: center;
}

.greenSwapText {
    font-size: 16px;
    font-weight: var(--main-fontBold-weight);
    color: white !important;
    margin-right: auto;
    margin-left: auto;
}

.greenText p {
    font-size: 14px;
    color: #A1E0D1;
    margin-right: auto;
    margin-left: auto;
}

.footerBlock {
    display: flex;
    align-items: center;
}

.footerBlock p {
    font-size: 14px;
    color: #56abe8;
    ;
    margin-right: auto;
}

.footerBlock img {
    margin-left: auto;
    height: 25px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.wrapper {
    display: flex;
    justify-content: center;
    margin-top: 48px;
    padding-bottom: 20px;
}

.container {
    /* width: var(--main-width-container); */
    /* height: calc(100vh - 72px); */
    display: flex;
    justify-content: space-between;
    box-shadow: 0 8px 25px 0 var(--main-box-shadow-color);
    border: solid 1px var(--background-color-2);
    background-color: var(--background-color);
    border-radius: 14px;
    margin-bottom: 3%;
    color: var(--main-white-color);
}

.blockBack {
    display: flex;
    align-items: center;
    cursor: pointer;
    opacity: .7;
    padding: 10px 0px 0px 10px;
}

.blockBack img {
    width: 14px;
    height: 14px;
    transform: rotate(90deg);
}

.blockBack p {
    margin-left: 3px;
    font-size: 16px;
    color: var(--main-grayInput-color);
}

.blockBack:hover {
    opacity: 1;
}

.blockData {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 20px;
}

.blockDataTitle {
    display: flex;
    margin-top: 15px;
}

.blockDataTitle h4 {
    color: var(--main-white-color);
    font-size: 20px;
    font-weight: var(--main-fontNormal-weight);
}

.blockDataPrice h3 {
    color: var(--main-white-color);
    font-size: 36px;
    font-weight: var(--main-fontNormal-weight);
}

.blockDataArrow {
    display: flex;
    align-items: center;
}

.blockDataArrow p {
    color: var(--main-white-color);
    font-size: 16px;
    font-weight: var(--main-fontNormal-weight);
}

.blockDataDiagram {
    margin-top: 20px;
}

.blockDataTabs {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.blockDataTabsItem {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--main-grayInput-color);
    border-radius: 12px;
    height: 40px;
}

.tabsItem {
    color: var(--main-white-color);
    padding: 5px 14px;
    font-weight: var(--main-fontBold-weight);
    cursor: pointer;
}

.tabsItemActive {
    color: var(--main-white-color);
    padding: 5px 14px;
    border-radius: 12px;
    background: var(--main-gray-color);
    color: var(--main-inputWindow-color);
    font-weight: var(--main-fontBold-weight);
}

@media only screen and (max-width : 700px) {
    .container {
        margin-bottom: 20%
    }
}
.button {
   width: 446px;
   height: 58px;
   border-radius: 6px;
   cursor: pointer;
   font-size: 20px;
   background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(11, 187, 177, 1) 0%, rgba(6, 120, 189, 1) 100%);
   border: none;
}

.buttonText {
   font-size: 20px;
   font-weight: var(--main-fontBold-weight);
   color: white;
}

@media only screen and (max-width : 480px) {
   .button {
      width: 332px;
   }
}
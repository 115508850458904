.wrapper {
    width: 800px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    min-height: 100vh;
}

.container {
    width: 100%;
    margin: auto;
    margin-top: 100px;
}

.title {
    font-size: 36px;
    font-weight: var(--main-fontNormal-weight);
    color: var(--main-white-color);
}

.table {
    margin-left: 2%;
    margin-right: 2%;
    box-shadow: 0 8px 25px 0 var(--main-box-shadow-color);
    border: solid 1px var(--background-color-2);
    background-color: var(--background-color) !important;
    border-radius: 14px;

}


.bottomContainer {
    margin-top: 50px;
    opacity: 0.5;
    margin-bottom: 30px;
}

@media only screen and (max-width : 800px) {
    .wrapper {
        width: 100%;
    }
}
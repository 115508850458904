.container {
    width: var(--main-width-percent);
    display: flex;
    align-items: center;
    padding-top: 10%;
    flex-direction: column;
    min-height: 100vh;
}

.window {
    width: 464px;
    border-radius: 14px;
    padding: 8px;
    z-index: 1;
    box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.23);
    border: solid 1px var(--background-color-2);
    background-color: var(--background-color);
}

.header {
    display: flex;
    justify-content: space-between;
    padding: 8px 12px;
    margin-bottom: 8px;
}

.header img {
    width: 22px;
}

.blockInputs {
    display: flex;
    flex-direction: column;
}

.blockInputsReverse {
    display: flex;
    flex-direction: column-reverse;
}

.btnArrow {
    display: flex;
    justify-content: center;
    z-index: 10;
    width: 40px;
    height: 40px;
    margin: -5px auto;
    background-color: var(--background-color);
    border-radius: 12px;

    align-items: center;
}

.btnWallet {
    margin-top: 15px;
}

.header p {
    color: var(--main-white-color);
    font-weight: var(--main-fontPlusNormal-weight);
}

.header img {
    cursor: pointer;
}

.header img svg circle:hover {
    filter:
        invert(77%) sepia(15%) saturate(436%) hue-rotate(189deg) brightness(82%) contrast(87%);
}

.footer {
    margin-top: 12px;
}

.footerBlockName {
    font-size: 12px;
    color: var(--main-gray-color);
    align-items: center;
}

.footerBlockName spam {
    font-size: 12px;
    color: white;
}

.footerBlockName p {
    text-align: center
}

.button {
    margin-top: 15px;
    width: 446px;
    height: 58px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 20px;
    background-color: var(--main-podBlue-color);
    border: none;
}

.buttonText {
    font-size: 20px;
    font-weight: var(--main-fontBold-weight);
    color: #5AEBC9;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 4%;
    margin-right: 4%;
}

.validityName {
    margin-top: 12px;
    width: 100%;
}

.validityBlockName {
    display: flex;
    align-items: center;
}

.validityBlockName p {
    font-size: 12px;
    color: red;
    margin-right: auto;
    margin-left: auto;
    font-weight: 600;
}

@media only screen and (max-width : 480px) {
    .window {
        width: 350px;
    }
}
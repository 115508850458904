.container {
    display: flex;
    justify-content: center;
    width: var(--main-width-percent);
    padding: 0 20px;
    position: sticky;
    top: 0;
    z-index: 100;
    padding-top: 2%;
    backdrop-filter: blur(24px);
}

.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
}

.blockLinks {
    margin-left: 10%;
    display: flex;
    align-items: center;
}

.linkLogo {
    margin-right: 25px;
}

.link {
    text-align: center;
    border-radius: 25px;
    padding: 10px 12px;
    margin-top: 6px;
    margin-right: 16px;
    width: 133px;
    margin-right: -2px;
    display: inline-block;
    color: var(--main-white-color);
    font-weight: var(--main-fontPlusNormal-weight);
}

.link:hover {
    padding: 10px 12px;
    background: #1E2232;
    border-radius: 22px;
}

.btnLogin {
    margin-left: auto;
}

.linkStyle {
    text-align: center;
    margin-left: 5%;
}

.button {
    width: 200px;
    height: 50px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 20px;
    background-color: var(--main-podBlue-color);
    border: none;
}

.buttonText {
    font-size: 20px;
    font-weight: var(--main-fontBold-weight);
    color: var(--main-gray-color);
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 4%;
}

#avatarImg {
    height: 20px;
    width: 20px;
    margin: 10px;
    background-color: #1d3d42;
    /* border-radius: 50%;  */
}

#avatarImgContainer {
    background-color: #1d3d42;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonDiv {
    margin-left: auto;
    margin-right: 10%;
}

.centralBlock {
    width: 100%;
}

.validityBlock {
    display: flex;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
    background-color: var(--main-podBlue-color);
    border: none;
}

.validityBlock img {
    height: 50px;
}

.buttonLogin {
    width: 200px;
    height: 50px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 20px;
    background-color: var(--main-podBlue-color);
    border: none;
}

.navStyle {
    width: 270px;
    height: 56px;
    margin: auto;
    background: var(--main-podBlue-color);
    border-radius: 25px;
}

.footerBlock {
    display: none;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 10px;
}

.linkFooterStyle {
    text-align: center;
}

@media only screen and (max-width : 1100px) {
    .buttonLogin {
        display: none;
    }

    .blockLinks {
        margin-left: 3%;
        display: flex;
        align-items: center;
    }

    .linkStyle {
        text-align: center;
    }
}

@media only screen and (max-width : 800px) {
    .navStyle {
        width: 270px;
        height: 56px;
        margin: auto;
        background: var(--main-podBlue-color);
        border-radius: 25px;
    }
}

@media only screen and (max-width : 700px) {
    .centralBlock {
        display: none;

    }

    .footerBlock {
        display: inline;
        z-index: 99;
    }
}
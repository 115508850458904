.headerBody {
    padding: 0 16px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search {
    margin-top: 20px;
    display: flex;
    align-items: center;
    width: 378px;
    height: 40px;
    border-radius: 12px;
    border: 1px solid var(--main-gray-color);
    background: var(--main-inputWindow-color);
}

.search img {
    margin: 0 10px;
    width: 20px;
    height: 20px;
}

.search input {
    color: var(--main-white-color);
    font-size: 16px;
    width: 100%;
    border: none;
    background: transparent;
}

.headersblock {
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0;
}

.headersblockItem {
    padding: 5px;
    max-width: 100px;
    display: flex;
    align-items: center;
    border: 1px solid rgba(153, 161, 189, 0.14);
    border-radius: 16px;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
}

.headersblockItemActive {
    padding: 5px;
    max-width: 100px;
    display: flex;
    align-items: center;
    border-radius: 16px;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid var(--main-blue-color);
    background: var(--main-podBlue-color);
}

.headersblockImg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.headersblockText {
    font-size: 16px;
    font-weight: var(--main-fontPlusNormal-weight);
    margin-right: 5px;
}

.headersblockTextActive {
    font-size: 16px;
    font-weight: var(--main-fontPlusNormal-weight);
    margin-right: 5px;
    color: var(--main-blue-color);
}

.headersblockItem:hover {
    background: var(--item-background-hover-color) !important;
}

.headersblockList {
    overflow-y: scroll;
    height: 400px;
}

.headersblockListItem {
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 56px;
    cursor: pointer;
}

.headersblockListItem:hover {
    background: var(--item-background-hover-color) !important;
}

.disable {
    color: var(--main-grayInput-color);
}

.disable:hover {
    background: transparent !important;
}

.disableImg {
    opacity: 50%;
}

.headersblockListImg {
    width: 36px;
    height: 36px;
    margin-right: 15px;
    border-radius: 50%;
}

.headersblockListItemText {
    display: flex;
    align-items: center;
}

.headersblockListItemText p {
    font-size: 16px;
    font-weight: var(--main-fontBold-weight);
    margin-right: 7px;
}

.headersblockListItemText img {
    width: 15px;
    height: 15px;
}

.headersblockListItemName {
    font-size: 12px;
    color: var(--main-grayInput-color);
}


.hashStyle {
    margin: auto;
    text-overflow: ellipsis;
    width: 60%;
    overflow: hidden;
}
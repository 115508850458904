.admissible {
    display: flex;
    align-items: center;
    margin-top: 12px;
}

.admissible img {
    margin-right: auto;
    margin-left: auto;
}

.validity {
    margin-top: 12px;
}

.validityBlock {
    display: flex;
    align-items: center;
    padding-right: 2%;
}

.validityBlock p {
    font-size: 14px;
    color: white;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 6%;
    margin-left: 1%;
}

.validityBlockSmall {
    display: flex;
    align-items: center;
}

.validityBlockSmall p {
    font-size: 10px;
    color: #C5CAD9;
    /*margin-right: 6%;*/
    margin-left: 1%;
}

.validityBlock img {
    height: 60px;
}

.validityComment {
    /* margin-top: 12px; */
}

.validityCommentBlock {
    display: flex;
    align-items: center;
}

.validityBlock div {
    cursor: pointer;
    font-size: 10px;
    color: #5AEBC9;
    margin-right: auto;
    margin-left: auto;
    border-radius: 8px;
    border: solid #A1E0D1;
    padding-right: 2%;
    padding-left: 2%;
    border-width: 1px;
}

.validityCommentBlock p {
    font-size: 10px;
    color: var(--main-gray-color);
    margin-right: auto;
    margin-left: auto;
}

.validityCommentBlock img {
    height: 25px;
    padding-right: 4px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.validityCommentBlockLeft {
    display: flex;
    align-items: center;
}

.validityCommentBlockLeft p {
    font-size: 10px;
    color: var(--main-gray-color);
}

.validityBlockLeft {
    display: flex;
    align-items: center;
}

.validityBlockLeft p {
    margin-top: 10px;
    font-size: 14px;
    color: white;
}

.validityBlockLeft img {
    height: 25px;
    padding-right: 4px;
}

.loginWindows {
    border-radius: 8px;
    background-color: var(--background-color-modal);
    /*border: thick double green;*/
    border: solid 3px #A1E0D1;
    outline: solid 6px var(--background-color-modal);
    border-width: 1px;
}



.validityCommentBlockLogin {
    cursor: pointer;
    display: flex;
}

.validityCommentBlockLogin p {
    margin-top: 4%;
    font-size: 10px;
    color: var(--main-gray-color);
}

.validityCommentBlockLogin img {
    height: 40px;
}

.validityHr {
    margin: 2%;
    color: #A1E0D1;
}

.footerBlock {
    display: flex;
    align-items: center;
}

#avatarImg {
    height: 15px;
    width: 15px;
    background-color: #1d3d42;
    margin: 10px;
}

#avatarImgContainer {
    background-color: #1d3d42;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footerBlock p {
    font-size: 14px;
    color: #56abe8;

    margin-right: auto;
}

.footerBlock img {
    margin-left: auto;
    height: 25px;
}

.logoImg {
    margin-left: 1%;
    height: 15px;
}

.textSpam {
    color: #56abe8;
    font-size: 10px;
}

@media only screen and (max-width : 480px) {
    .validityCommentBlockLeft p {
        padding-top: 2%;
    }
}
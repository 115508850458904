.loginWrapper {
    background: url(../../images/background.jpg) no-repeat center center fixed;
    background-size: cover;
    background-position: center;
    width: 100vw;
    height: 100vh;
    display: relative;
    padding-top: 185px;
}

.buttonWrapper {
    display: flex;
    /* padding-top: 120px; */
}

.loginLogo {
    margin-top: 0px;
}


.loginColorText {
    font-size: 16px;
    display: flex;
    /*margin: 120px auto auto auto;*/
    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4);
    background-image: linear-gradient(225deg, #6a45d6, #a034d1);
    width: 200px;
    height: 200px;
    margin-bottom: 5px;
    color: #fff;
    border: none;
    padding: 30px;
}

.loginWalletColorText {
    font-size: 16px;
    display: flex;
    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.4);
    background-image: linear-gradient(225deg, #316aa0, #00b1a6);
    width: 200px;
    height: 200px;
    margin-bottom: 5px;
    color: #fff;
    border: none;
    padding: 30px;
}

.loginBottomBox {
    position: absolute;
    bottom: 60px;
    right: calc(50% - 75px);
}

.walletLink {
    text-decoration: none;
}

@media only screen and (max-width: 500px) {
    .loginColorText {
        width: 150px;
        height: 150px;
    }

    .loginWalletColorText {
        width: 150px;
        height: 150px;
    }
}

@media only screen and (max-height: 750px) {
    .loginWrapper {
        padding-top: 125px;
    }
}

@media only screen and (max-height: 650px) {
    .loginWrapper {
        padding-top: 100px;
    }
}

@media only screen and (max-height: 600px) {
    .buttonWrapper {
        padding-top: 100px;
    }

    .loginWrapper {
        padding-top: 65px;
    }
}

@media only screen and (max-height: 550px) {
    .buttonWrapper {
        padding-top: 75px;
    }

    .loginWrapper {
        padding-top: 50px;
    }
}

.greenBlock {
    margin-top: 12px;
    border-radius: 5px;
}

.greenText {
    display: flex;
    align-items: center;
    height: 50px;
}

.greenText p {
    font-weight: bold;
    font-size: 14px;
    color: #5AEBC9;
    margin-right: auto;
    margin-left: auto;
}
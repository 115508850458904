@use "shared";

@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: shared.$default-font-family !important;
  background-image: var(--background-gradient);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --background-gradient: linear-gradient(to top, #1f1d47, #0a0b43);
  --background-color: #1a1946;
  --background-color-2: #332357;
  --background-color-arrow-button: #13254a;
  --background-color-modal: #0f0f3a;
  --background-color-modal-2: #100035;
  --item-background-hover-color: #1D202A;
  --check-icon-color: #00ffda;
  --main-page-color: #0D0F1E;
  --main-background-color: #121625;
  --main-box-shadow-color: rgba(0, 0, 0, 0.31);
  --main-white-color: #ffffff;
  --main-blue-color: #4C82FB;
  --main-podBlue-color: #1D2C50;
  --main-blueBackground-color: #1D2B54;
  --main-btnWindowOne-color: #293249;
  --main-inputWindow-color: #141B2B;
  --main-gray-color: #858DA7;
  --main-grayInput-color: #5E6887;
  --main-violet-color: #9148FA;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.background_gradient_color {
  background-image: linear-gradient(180deg, hsla(246, 64%, 27%, 1), hsla(247, 71%, 25%, 1)) !important;
}



::placeholder {
  opacity: 1 !important;
  color: #adadd0 !important;
}
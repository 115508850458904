.admissible {
    display: flex;
    align-items: center;
    margin-top: 12px;    
}

.admissible img{
    margin-right: auto;
    margin-left: auto;
}

.validity {
    margin-top: 12px;
}

.validityBlock {
    display: flex;
    align-items: center;
}

.validityBlock p {
    font-size: 14px;
    color: white;
    margin-right: auto;
    margin-left: auto;
}
.redBlock {
    margin-top: 12px;
    background-color: red;
    border-radius: 5px;
}

.redText {
    display: flex;
    align-items: center;
}

.redText p {
    font-size: 14px;
    color: white;
    margin-right: auto;
    margin-left: auto;
}



.validity {
    margin-top: 12px;
}

.validityBlock {
    display: flex;
    align-items: center;
}

.validityBlock p {
    font-size: 14px;
    color: white;
    margin-right: auto;
    margin-left: auto;
}
.redBlock {
    margin-top: 12px;
    background-color: red;
    border-radius: 5px;
}

.redText {
    display: flex;
    align-items: center;
}

.redText p {
    font-size: 14px;
    color: white;
    margin-right: auto;
    margin-left: auto;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.greenBlock {
    margin-top: 12px;
    background-color: #3A427B;
    border-radius: 5px;
}

.greenText {
    display: flex;
    align-items: center;
}

.greenText p {
    font-size: 14px;
    color: #A1E0D1;
    margin-right: auto;
    margin-left: auto;
}

.inputBlock {
    display: flex;
}

.inputBlock input {
    border: none;
    background: rgb(255, 255, 255);
    width: 100%;  
    height: 15px;
    color: black;
    font-size: 12px;
    margin-right: auto;
    margin-left: auto;
}

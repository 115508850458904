.container { 
  background: var(--main-podBlue-color);
  color: white;
  border: 5px solid var(--main-podBlue-color);
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: -5px;
  margin-top: -5px;
}


.arrowTop,
.arrowBottom {
    width: 10px;
    height: 10px;
    cursor: pointer;
}

.arrowTop polygon {
  fill: green; 
  transition: fill 0.5s ease-out;
}
.arrowBottom polygon {
    fill: red; 
    transition: fill 0.5s ease-out;
}
.arrowTop {
    transform: rotate(315deg);
}
.arrowBottom {
    transform: rotate(45deg);
}
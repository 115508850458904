#bottom_container {
    width: fit-content;
    opacity: 0.5;
    margin: 100px auto 30px auto;
}

/* Media query for screens smaller than 450px */
@media (max-width: 700px) {
    #bottom_container {
        margin: 50px auto 80px auto;
        /* Custom margin for screens smaller than 450px */
    }
}
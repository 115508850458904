.admissible {
    display: flex;
    align-items: center;
    margin-top: 12px;    
}

.admissible img{
    margin-right: auto;
    margin-left: auto;
}

.validity {
    margin-top: 12px;
}

.validityBlock {
    display: flex;
    align-items: center;
}

.validityBlock p {
    font-size: 14px;
    color: white;
    margin-right: auto;
    margin-left: auto;
}

.validityComment {
    margin-top: 12px;
}

.validityCommentBlock {
    display: flex;
    align-items: center;
}

.validityCommentBlock p {
    font-size: 10px;
    color: var(--main-gray-color);
    margin-right: auto;

}

.validityCommentBlock img{
    height: 25px;
    margin-left: auto;
}

.greenBlock {
    margin-top: 12px;
    background-color: #3A427B;
    border-radius: 5px;
}

.greenText {
    display: flex;
    align-items: center;
}

.greenText p {
    font-size: 14px;
    color: #A1E0D1;
    margin-right: auto;
    margin-left: auto;
}

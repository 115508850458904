.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    border: none;
    background-color: var(--background-color-2);
    width: auto;
    margin: 5px 10px;
    max-width: 446px;
    height: 98px;
    z-index: 1;
    padding: 0px 10px;
}

.containerActive {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    background: var(--background-color-2);
    width: auto;
    margin: 0px;
    max-width: 446px;
    height: 98px;
    padding: 0px 10px;
    border: solid 1px var(--background-color-2);

}

.input {
    border: none;
    background-color: transparent !important;
    width: 100%;
    height: 44px;
    color: var(--main-white-color);
    font-size: 36px;
    text-overflow: ellipsis;
    padding-right: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type='number'] {
    -moz-appearance: textfield;
    width: 100%;
}

.blockInput {
    background-color: transparent !important;
    color: var(--main-gray-color);
    width: -webkit-fill-available;
}

.blockInput p {
    background: transparent !important;
    font-size: 14px;
    padding-top: 10px;
    height: 20px;
}

.input:active,
:hover,
:focus {
    outline: 0;
    outline-offset: 0;
}

.btn {
    border-radius: 16px;
    border: none;
    padding: 0px 10px;
    color: var(--main-white-color);
    background-color: rgba(0, 255, 218, 0.1);
    height: 32px;
    cursor: pointer;
    transition: background .1s ease-in;
}

.btn:hover {
    background-color: rgb(51, 59, 79);
    transition: background .1s ease-in;
}

.btnNotToken {
    border-radius: 16px;
    border: none;
    padding-left: 4px;
    color: var(--main-white-color);
    background-color: rgba(0, 255, 218, 0.1);
    padding: 10px;
    height: 32px;
    cursor: pointer;
    transition: background .1s ease-in;
    display: flex;
    align-items: center;
}

.btnNotToken span {
    font-size: 18px;
    font-weight: var(--main-fontBold-weight);
    margin: 0 5px;
    text-wrap: nowrap;
}

.contentBtn {
    display: flex;
    align-items: center;
}

.errorText {
    color: white;
    text-indent: 5px;
    font-size: 11px;
    margin-top: 5px;
}

#input_wrapper {
    height: "auto"
}

.contentBtn span {
    background: transparent;
    font-size: 21px;
    font-weight: var(--main-fontBold-weight);
}

.contentBtnImg {
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 50%;
    margin-right: 3px;
}

.headersblockImg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 50%;
}

.headersblockText {
    font-size: 16px;
    font-weight: var(--main-fontPlusNormal-weight);
    margin-right: 5px;
}

.balance {
    font-size: 14px;
    color: white;
    text-overflow: ellipsis;
    overflow: hidden;
}

.headersblockTextImg {
    font-size: 16px;
    font-weight: var(--main-fontPlusNormal-weight);
    margin-right: 5px;
    margin-left: 5px;
}

@media only screen and (max-width : 480px) {
    .btnNotToken span {
        font-size: 14px;
    }
}
.accordionBoard {
    background: var(--background-color-modal);
    margin-top: 2% !important;
}

;

.button {
    cursor: pointer;
    width: 40px;
    height: 40px;
    background: var(--main-btnWindowOne-color);
    border: 4px solid var(--main-page-color);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#errorText {
    color: red;
}

.validity {
    margin-top: 12px;
}

.validityName {
    margin-top: 12px;
    width: 100%;
}

.validityBlockName {
    display: flex;
    align-items: center;
}

.validityBlockName p {
    font-size: 12px;
    color: white;
    margin-right: auto;
}

.validityBlockName img {
    padding-right: 4px;
}

.validityBlockName div {
    font-size: 12px;
    color: white;
    margin-left: auto;
}

.loginWindows {
    border-radius: 8px;
    background-color: var(--background-color-modal);
    /*border: thick double green;*/
    border: solid 1px #347984;
    outline: solid 6px #1B214B;
}

.validityHr {
    margin: 2%;
    color: #347984;
}

.validityBlock1 {
    display: flex;
    align-items: center;
}

.validityBlock1 p {
    font-size: 14px;
    color: white;
    margin-right: auto;
    margin-left: 2%;
}

.validityBlock1 div {
    font-size: 14px;
    color: white;
    margin-left: auto;
    margin-right: 2%;
}

.validityBlock2 {
    display: flex;
    align-items: center;
}

.validityBlock2 p {
    font-size: 14px;
    color: white;
    margin-right: auto;
    margin-left: 2%;
}

.validityBlock2 div {
    font-size: 14px;
    color: var(--main-gray-color);
    margin-left: auto;
    margin-right: 2%;
}


.validityBlock3 {
    display: flex;
    align-items: center;
}

.validityBlock3 p {
    font-size: 14px;
    color: var(--main-gray-color);
    margin-right: auto;
    margin-left: 2%;
}

.validityBlock3 div {
    font-size: 14px;
    color: var(--main-gray-color);
    margin-left: auto;
    margin-right: 2%;
}


.validityBlock4 {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}

.validityBlock4 p {
    font-size: 14px;
    color: var(--main-gray-color);
    margin-right: auto;
    margin-left: 2%;
}

.validityBlock4 div {
    font-size: 14px;
    color: var(--main-gray-color);
    margin-left: auto;
    margin-right: 2%;
}

.changeSlippage {
    font-size: 10px;
    padding-top: 1%;
    margin-top: 10px;
}

.input {
    border: none;
    background-color: #1c2148 !important;
    max-width: 50px;
    height: 20px;
    color: white;
    font-size: 12px;
}

@media only screen and (max-width : 480px) {
    .input {
        max-width: 39px;
    }

    .changeSlippage {
        padding-top: 2%;
    }
}
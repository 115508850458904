.button {
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 8px;
    background-color: var(--background-color-arrow-button);
    border: none;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}